
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import { DataEmitterService } from "@/services/DataEmiterService";
import Editor from '@tinymce/tinymce-vue'
import VueQRCodeComponent from 'vue-qrcode-component'
import StringUtil from '@/utils/StringUtil'
import TranslationWrapper from '@/layouts/components/TranslationWrapper.vue'
import TranslationUtil from '@/utils/TranslationUtil'

export default {

  components: {
    Multiselect,
    TranslationWrapper,
    'editor': Editor,
    'qr-code': VueQRCodeComponent
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) )

    return {
      translations: {},
      spacesSnippet: snippets ?? {},
      spacesList: [],
      paginatedSpacesList: [],
      paginatedSearchedSpacesList: [],
      spacesPaginatedList: [],
      rolesList: [],
      spacesListUntouched: [],
      searchedData: [],
      model: +sessionStorage.getItem( 'model' ) ?? 0,
      searchField: '',
      newEvent: {
        name: '',
        description: ''
      },
      newSpace: {
        description: '',
        id: '',
        name: '',
        photos: '',
        qrCode: '',
        mapPosition: null,
        maxNumberOfParticipants: 0,
        isBookable: '',
        isEventCapable: '',
        isInvitational: '',
        availableFavours: '',
        childrenSpaces: [],
        floorId: null,
        facilityId: null
      },
      dialog: false,
      spaceDialog: false,
      eventDialog: false,
      editEventDialog: false,
      addEventsDialog: false,
      dialogDelete: false,
      dialogDeleteSpace: false,
      dialogCarousel: false,
      deleteEventDialog: false,
      dialogUpgrade: false,
      submitted: false,
      globalError: true,
      eventsSubmitted: false,
      spaceSubmitted: false,
      mobileNavBar: false,
      facilityId: this.$route.params.id,
      spaceId: this.$route.params.spaceId,
      editedIndex: -1,
      selectedProducts: [],
      selectedServices: [],
      editedItem: {
        description: '',
        credit: 0,
        id: '',
        name: '',
        photos: '',
        qrCode: '',
        mapPosition: null,
        maxNumberOfParticipants: 0,
        isBookable: '',
        isEventCapable: '',
        isInvitational: '',
        availableFavours: [],
        childrenSpaces: []
      },
      defaultItem: {
        description: '',
        credit: 0,
        id: '',
        name: '',
        photos: '',
        qrCode: '',
        mapPosition: null,
        maxNumberOfParticipants: 0,
        isBookable: '',
        isEventCapable: '',
        isInvitational: '',
        availableFavours: [],
        childrenSpaces: []
      },
      timeRegex: /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
      multiSelectRoles: [],
      carouselPhotos: [],
      sortBy: 'id',
      editedPrefix: '',
      sortDesc: true,
      errorKey: '',
      addEventsDialogErrorKey: '',
      toDeletePhotoIndex: '',
      facilityData: null,
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldSpace: {},
      editedEventItem: {},
      toDeleteEvent: {},
      addressesDataList: [],
      showSpaces: [],
      spaceServices: [],
      allServices: [],
      products: [],
      eventsList: [],
      eventsNameList: [],
      oldEventsList: [],
      chips: [],
      chipsNameList: [],
      oldChips: [],
      eventsListHasBeenEdited: false,
    }
  },

  computed: {
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },

    defaultSpaceImage() {
      return require( '../../assets/images/facilities/no-image.svg' );
    },

    headers() {
      return [
        { text: this.spacesSnippet.id, value: 'id', width: '0px' },
        { text: this.spacesSnippet.name, value: 'name', width: '0px' },
        { text: this.spacesSnippet.description, value: 'description', width: '0px' },
        { text: this.spacesSnippet.isEventCapable, value: 'isEventCapable', width: '0px' },
        { text: this.spacesSnippet.isInvitational, value: 'isInvitational', width: '0px' },
        { text: this.spacesSnippet.isBookable, value: 'isBookable', width: '0px' },
        {
          text: this.spacesSnippet.maxNumberOfParticipants,
          value: 'maxNumberOfParticipants',
          width: '0px'
        },
        { text: this.spacesSnippet.mapPosition, value: 'mapPosition', width: '0px' },
        { text: this.spacesSnippet.actions, value: 'actions', width: '0px', sortable: false },
      ]
    },


    FavourHeaders() {
      return [
        { text: this.spacesSnippet.id, value: 'id', width: '5%' },
        { text: this.spacesSnippet.name, value: 'name', width: '15%' },
        { text: this.spacesSnippet.description, value: 'description', width: '15%' },
        { text: this.spacesSnippet.actions, value: 'actions', width: '10%', sortable: false },
      ]
    },
  },

  watch: {

    spaceDialog( val ) {
      val || this.closeSpaceDialog()
    },

    model( val ) {
      sessionStorage.setItem( 'model', val );
    },

    dialogCarousel( val ) {
      val || this.closeDialogCarousel()
    },

    eventDialog( val ) {
      val || this.closeEventDialog()
    },

    editEventDialog( val ) {
      val || this.closeEditEventDialog()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },

    deleteEventDialog( val ) {
      val || this.closeDeleteEvent()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

  },

  beforeRouteLeave( to, from, next ) {
    sessionStorage.removeItem( 'model' );
    StorageService.deleteFacilitySpaceFromStorage();
    DataEmitterService.$emit( 'route-change' )
    next();
  },

  async mounted() {
    await this.getProductsAPI();
    await this.getSpaceApi();
    await this.getFacilityApi();
    await this.getSpaceServices();
    this.loading = false;
  },

  methods: {
    downloadQrCode() {
      const canvas = document.getElementById( 'spaceQrCode' ).querySelector( 'canvas' );
      const link = document.createElement( "a" );
      link.download = this.editedItem.name + ' QR code.png';
      link.href = canvas.toDataURL();
      link.style.opacity = '0';
      link.style.position = 'absolute';
      document.body.append( link );
      link.click();
      link.remove();
    },

    toText( html ) {
      return StringUtil.toText( html );
    },

    goBack() {
      this.$router.push( `/facility-details/${ this.facilityId }` )
    },

    closeSpaceDialog() {
      this.spaceDialog = false;
      this.spaceSubmitted = false;
      this.submitted = false;
      this.newSpace = this.getObjClone( this.defaultItem );
      this.errorKey = '';
      this.$refs.spaceFileupload?.reset();
    },

    async saveSpace() {
      this.spaceSubmitted = true;
      this.newSpace.qrCode = this.generateRandomUniqueString( 2 );
      let isValid = this.spaceDataIsValid();
      if ( isValid !== true ) {
        return;
      }
      this.newSpace['photoIds'] = await this.uploadSpaceImages( this.newSpace );

      this.newSpace.services = this.chipsNameList;
      this.newSpace.parentId = this.editedItem.id;
      this.newSpace.facilityId = this.facilityId;
      this.newSpace.isBookable = this.editedItem.isBookable;
      this.newSpace.isEventCapable = this.editedItem.isEventCapable;
      this.newSpace.isInvitational = this.editedItem.isInvitational;
      this.newSpace.maxNumberOfParticipants = this.editedItem.maxNumberOfParticipants;

      let spaceData = await ApiRequestService.postRequest( 'api/admin-facility-spaces', this.newSpace );
      if ( !spaceData ) {
        return;
      }
      if ( spaceData?.errorMessage ) {
        this.errorKey = spaceData?.errorMessage?.split( ',' );
        return;
      }

      this.editedItem.childrenSpaces.push( spaceData );
      this.closeSpaceDialog();
    },

    deletePhoto( photoIndex ) {
      event.stopPropagation();
      this.toDeletePhotoIndex = photoIndex;
      this.dialogDelete = true;
    },

    async deleteSpacePhoto() {
      let selected = this.editedItem.photos.splice( this.toDeletePhotoIndex, 1 );
      await ApiRequestService.deleteRequest( `api/facility-photos/facility-space-photos/${ selected[0].id }` );
      this.closeDelete();
    },

    openSpaceDetails( space ) {
      if ( space && space.id ) {
        let resolvedLocation = this.$router.resolve( {
          name: `space`,
          params: { spaceId: space.id }
        } )?.href;
        sessionStorage.setItem( 'model', 0 );
        this.$router.push( resolvedLocation );
      }
    },

    updateMainTranslations() {
      if ( this.translations['en'] ) {
        this.translations['en'].name = this.editedItem.name;
        this.translations['en'].description = this.editedItem.description;
      }
    },

    updateTranslations( data ) {
      this.translations = data;
      this.editedItem = {
        ...this.editedItem,
        ...this.translations['en'],
      }
    },

    setTranslations() {
      this.translations = TranslationUtil.transformToRequestDto(
        this.editedItem.translations,
        {
          description: this.editedItem.description,
          name: this.editedItem.name,
        }
      );
    },

    async getSpaceApi() {
      this.editedItem = await ApiRequestService.getRequest( `api/admin-facility-spaces/${ this.spaceId }` );
      this.setTranslations();
      this.sortFacilitySpacePhotos();

      StorageService.setFacilitySpaceToStorage( this.editedItem );
      DataEmitterService.$emit( 'route-change' )

      this.editedItem?.products?.forEach( item => {
        this.products?.map( pr => {
          if ( pr.id === item.id ) {
            pr.selected = true
          }
        } );
        this.selectOrUnselectProduct( item.id )
      } );

      this.oldSpace = this.getObjClone( this.editedItem )
    },

    selectOrUnselectProduct( id ) {
      if ( this.selectedProducts.indexOf( id ) === -1 ) {
        this.selectedProducts.push( id );
      } else {
        this.selectedProducts = this.selectedProducts.filter( item => item !== id );
      }
    },

    selectOrUnselectService( id ) {
      if ( this.selectedServices.indexOf( id ) === -1 ) {
        this.selectedServices.push( id );
      } else {
        this.selectedServices = this.selectedServices.filter( item => item !== id );
      }
    },

    async getFacilityApi() {
      this.facilityData = StorageService.getFacilityFromStorage();
      if ( !this.facilityData ) {
        this.facilityData = await ApiRequestService.getRequest( `api/admin-facilities/${ this.facilityId }` );
      }
    },
    async getProductsAPI() {
      this.products = await ApiRequestService.getRequest( `api/admin-products/facility/${ this.facilityId }` );
      if ( this.products && this.products.length ) {
        this.products.forEach( item => item.selected = false );
      }
    },

    async selectAsFacilitySpaceMainPhoto( photoIndex ) {
      event.stopPropagation();
      if ( photoIndex === null || photoIndex === undefined ) {
        return;
      }

      let selected = this.editedItem.photos.splice( photoIndex, 1 );

      this.editedItem.photos.forEach( item => item.isMain = false );
      selected[0].isMain = true;

      this.editedItem.photos.unshift( selected[0] );
      await ApiRequestService.postRequest( `api/facility-photos/facility-space-photos-main/${ this.editedItem.id }`, { photoId: selected[0].id } )
    },

    sortFacilitySpacePhotos() {
      let mainPhotoIndex = this.editedItem.photos.findIndex( ( f ) => f.isMain );
      let mainPhoto = this.editedItem.photos.splice( mainPhotoIndex, 1 )[0];
      if ( mainPhoto ) {
        this.editedItem.photos.unshift( mainPhoto );
      }
    },
    async getSpaceServices() {
      //TODO: check with Syuzanna
      this.allServices = await ApiRequestService.getRequest( `api/facility-favours/facility/${ this.facilityId }` );
      if ( this.allServices && this.allServices.length ) {
        this.allServices.forEach( item => item.selected = false );
      }
      this.editedItem?.availableFavours?.forEach( item => {
        this.allServices?.map( pr => {
          if ( pr.id === item.id ) {
            pr.selected = true
          }
        } );
        this.selectOrUnselectService( item.id )
      } );
    },

    async getSpaceEventsApi( spaceId ) {
      //TODO: implement API
      this.chips = null//await ApiRequestService.getRequest( `api/facility-services/${ spaceId }` );
      this.oldChips = this.chips;

      if ( this.chips?.errorMessage ) {
        this.chips = [ { id: 0, name: 'TV' }, { id: 1, name: 'Food' } ];
        this.oldChips = this.getObjClone( this.chips )
      }

      this.chipsNameList = this.chips?.map( item => item.name );
    },

    getToLocaleDate( date ) {
      let options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
      return new Date( date ).toLocaleDateString( 'en-US', options ) ?? 'null'
    },

    async getChangedSpaceData( oldSpace ) {
      const dataFormUpdate = {};
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( oldSpace[key] === this.editedItem[key] ) {
          continue
        }
        if ( key === 'photos' || key === 'floors' || key === 'childrenSpaces' ) {
          continue
        }

        if ( key === 'products' ) {
          let readyProducts = [];
          for ( let [ ProductKey, ProductValue ] of Object.entries( this.selectedProducts ) ) {
            readyProducts.push( ProductValue );
          }
          dataFormUpdate[key] = readyProducts;
          continue;
        }
        if ( key === 'availableFavours' ) {
          let readyServices = [];
          for ( let [ ServiceKey, ServiceValue ] of Object.entries( this.selectedServices ) ) {
            readyServices.push( ServiceValue );
          }
          if ( readyServices.length ) {
            dataFormUpdate[key] = readyServices;
          }
          continue;
        }

        if ( key === 'translations' ) {
          dataFormUpdate[key] = this.translations;
          continue;
        }

        dataFormUpdate[key] = this.editedItem[key]
      }

      return dataFormUpdate
    },

    async saveUploadedImages() {
      const dataFormUpdate = {};

      if ( this.editedItem?.photoIds?.length ) {
        dataFormUpdate['photoIds'] = await this.uploadSpaceImages( this.editedItem );
      }

      const updatedSpace = await ApiRequestService.updateRequest( `api/admin-facility-spaces/${ this.editedItem.id }`, dataFormUpdate )
      if ( updatedSpace.errorMessage ) {
        return
      }

      if ( updatedSpace ) {
        this.editedItem = updatedSpace;
        this.sortFacilitySpacePhotos();
      }

      this.$refs.fileupload.reset();
    },

    openCarousel( photoIndex ) {
      let carouselPhotos = this.getObjClone( this.editedItem.photos );
      carouselPhotos = ( carouselPhotos.splice( photoIndex, carouselPhotos.length ) ).concat( carouselPhotos.splice( 0, photoIndex ) );

      if ( carouselPhotos?.length ) {
        this.carouselPhotos = carouselPhotos;
        this.dialogCarousel = true;
      }
    },

    async updateSpaceRequest( oldSpace ) {
      let existsPhotosCount = this.editedItem?.photos?.length;
      if ( this.editedItem?.photoIds?.length + existsPhotosCount > 10 ) {
        this.errorKey = `Maximum number of images allowed 10.You have already ${ existsPhotosCount } images`;
        return;
      }

      const dataFormUpdate = await this.getChangedSpaceData( oldSpace );
      const updatedSpace = await ApiRequestService.updateRequest( `api/admin-facility-spaces/${ this.editedItem.id }`, dataFormUpdate )
      if ( updatedSpace.errorMessage ) {
        return
      }

      if ( updatedSpace ) {
        this.editedItem = updatedSpace;
        this.setTranslations();
        this.oldSpace = this.getObjClone( this.editedItem );
        this.sortFacilitySpacePhotos();
      }
    },

    dataIsValid() {
      const nameIsValid = this.facilityIsValid( this.editedItem?.name?.trim() )
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }

      /*if (this.editedItem?.isBookable && this.editedItem?.isEventCapable) {
        isNotValid.push(this.spacesSnippet.cantBeBookableAndEventableAtTheSameTime)
      }*/

      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    spaceDataIsValid() {
      const nameIsValid = this.facilityIsValid( this.newSpace?.name?.trim() )
      const qrCodeIsValid = this.facilityIsValid( this.newSpace?.qrCode?.trim() )
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }
      if ( qrCodeIsValid !== true ) {
        isNotValid.push( qrCodeIsValid )
      }

      /*if (this.newSpace?.isBookable && this.newSpace?.isEventCapable) {
        isNotValid.push(this.spacesSnippet.cantBeBookableAndEventableAtTheSameTime)
      }*/


      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    facilityIsValid( v ) {
      return ( v && v.length > 0 ) || this.spacesSnippet.required
    },


    facilityWorkDaysAreValid( v ) {
      let valid = true;
      v.forEach( item => {
        if ( !this.timeRegex.test( item.startTime ) || !this.timeRegex.test( item.endTime ) ) {
          valid = 'Wrong time format';
          return;
        }
      } );
      if ( valid === true ) {
        this.globalError = true;
      }
      return valid;

    },

    openAddNewSpaceDialog() {
      this.spaceDialog = true;
      this.newSpace.floorId = this.facilityData.facility.floors[0]?.id;
    },

    async editItem( item ) {
      let paginatedList = this.searchField ? this.paginatedSearchedSpacesList : this.paginatedSpacesList;
      let list = this.searchField ? this.searchedSpacesList : this.spacesList;
      this.editedIndex = paginatedList.findIndex( fc => fc.id === item.id );
      this.listEditedIndex = list.findIndex( fc => fc.id === item.id );
      this.editedItem = this.getObjClone( paginatedList[this.editedIndex] );

      await this.getSpaceEventsApi( this.editedItem.id );

      this.dialog = true
      this.disableKeyField = true
      this.oldSpace = this.getObjClone( this.editedItem )
    },

    editEvent( item ) {
      event.stopPropagation();
      this.editedEventItem = this.getObjClone( this.eventsList ).find( event => event?.name === item.name );
      this.editEventDialog = true;
    },

    addEvent() {
      if ( !this.newEvent.name ) {
        this.addEventsDialogErrorKey = 'Wrong event';
        return;
      }
      if ( !this.newEvent.description ) {
        this.addEventsDialogErrorKey = 'Wrong event';
        return;
      }
      if ( this.eventsList?.findIndex( event => event.name.toLowerCase() === this.newEvent.name.trim().toLowerCase() ) !== -1 ) {
        this.addEventsDialogErrorKey = 'This event exists';
        return;
      }
      this.addEventsDialogErrorKey = '';
      //TODO: send add event api;
      this.eventsList.push( {
        id: this.eventsList?.length,
        name: this.newEvent.name,
        description: this.newEvent.description,
      } )
      this.oldEventsList = this.getObjClone( this.eventsList );
      this.getEditedEventsNames();
      this.newEvent.name = '';
      this.newEvent.description = '';
    },

    deleteSelectedEvent( item ) {
      event.stopPropagation();
      this.chips = this.chips.filter( chip => chip.name !== item );
      this.getEditedEventsNames();
    },
    deleteEvent( item ) {
      event.stopPropagation();
      this.toDeleteEvent = item;
      this.deleteEventDialog = true;
    },

    deleteSpaceItem( item ) {
      if ( !item || !item.id ) {
        return;
      }
      this.toDeleteSpace = this.getObjClone( item );
      this.dialogDeleteSpace = true
    },

    async deleteFacilitySpace() {
      if ( !this.toDeleteSpace || !this.toDeleteSpace?.id ) {
        return;
      }
      await ApiRequestService.deleteRequest( `api/admin-facility-spaces/${ this.toDeleteSpace.id }` );
      this.editedItem.childrenSpaces = this.editedItem.childrenSpaces.filter( item => item.id !== this.toDeleteSpace.id );
      this.closeDelete();
    },

    deleteItem( item ) {
      let list = this.searchField ? this.paginatedSearchedSpacesList : this.paginatedSpacesList;
      this.editedIndex = list.findIndex( fc => fc.id === item.id );
      this.editedItem = this.getObjClone( list[this.editedIndex] );
      this.dialogDelete = true
    },

    upgradeItem( item ) {
      this.editedIndex = this.spacesList.indexOf( item )
      this.dialogUpgrade = true
    },


    async deleteEventConfirm() {
      this.eventsList = this.eventsList?.filter( chip => chip.name !== this.toDeleteEvent.name );
      this.oldEventsList = this.getObjClone( this.eventsList );
      this.getEditedEventsNames();
      //TODO: send delete event request

      this.closeDeleteEvent()
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) );
    },

    closeDelete() {
      this.dialogDelete = false;
      this.dialogDeleteSpace = false;
      this.dialogDeletePhoto = false;
      this.submitted = false;
    },

    closeDialogCarousel() {
      this.dialogCarousel = false;
      this.carouselPhotos = {}
    },

    closeDeleteEvent() {
      this.deleteEventDialog = false;
    },

    closeUpgrade() {
      this.dialogUpgrade = false;
      this.submitted = false;
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) };
        this.editedIndex = -1;
      } )
    },

    generateRandomUniqueString( len ) {
      let text = '';
      for ( let i = 0; i < len; i++ ) {
        text += Math.random().toString( 36 ).slice( 2 ) + Date.now()
      }

      return text
    },


    async uploadSpaceImages( dataToCreate ) {
      let photoIds = [];

      for ( let i = 0; i < dataToCreate?.photoIds?.length; i++ ) {
        const formData = new FormData();
        formData.append( "file", dataToCreate?.photoIds[i], dataToCreate?.photoIds[i].name );

        let data = await ApiRequestService.postRequest( 'api/facility-photos/facility-space-photos', formData );
        photoIds.push( data?.id );
      }

      return photoIds;
    },

    async updateSpace() {
      this.globalError = this.dataIsValid();

      if ( this.globalError !== true ) {
        return;
      }

      await this.updateSpaceRequest( this.oldSpace );
    },

    async save() {
      this.submitted = true;
      await this.updateSpace();
    },

    getEditedEventsNames() {
      this.chipsNameList = this.chips?.map( item => item.name );
      this.eventsNameList = this.eventsList?.map( item => item.name );
    },

    saveEditedEventItem() {
      let editedEventItemIndex = this.eventsList?.findIndex( item => item.id === this.editedEventItem.id );
      this.$set( this.eventsList, editedEventItemIndex, this.editedEventItem );

      this.chipsNameList.forEach( item => {
        let selectedEditedEventItemIndex = this.chips.findIndex( chip => chip.name === item );

        let editedEventItemIndex = this.oldEventsList.findIndex( chip => chip.name === item );
        let editedEventItem = this.eventsList[editedEventItemIndex];

        if ( selectedEditedEventItemIndex !== -1 ) {
          this.$set( this.chips, selectedEditedEventItemIndex, {
            id: editedEventItem.id,
            name: editedEventItem.name
          } );
        } else {
          if ( editedEventItem ) {
            this.chips.push( {
              id: editedEventItem.id,
              name: editedEventItem.name,
            } )
          }
        }
      } )

      this.getEditedEventsNames();

      this.eventsListHasBeenEdited = true;
      this.closeEditEventDialog();
    },

    closeEditEventDialog() {
      this.editEventDialog = false;
      this.editedEventItem = '';
      this.eventsSubmitted = true;
    },
    saveEvents() {
      this.oldChips = this.getObjClone( this.chips );
      if ( this.eventsListHasBeenEdited ) {
        //TODO: send events list update request
      }
      //TODO: end current facility events list update request
      this.closeEventDialog();
    },


    setPhotos( files ) {
      if ( files && files.length ) {
        if ( files.length + this.editedItem.photos.length > 10 ) {
          this.errorKey = 'Is not able to upload more then 10 images';
          this.$refs.fileupload.reset();
        } else {
          this.errorKey = '';
          this.editedItem.photoIds = files;
        }
      }
    },
    setNewSpacePhotos( files ) {
      if ( files && files.length ) {
        if ( files.length + this.newSpace.photos.length > 10 ) {
          this.errorKey = 'Is not able to upload more then 10 images';
          this.$refs.spaceFileupload.reset();
        } else {
          this.errorKey = '';
          this.newSpace.photoIds = files;
        }
      }
    },

  }
}

